import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import icClose from '@iconify/icons-ic/close';
import { AuthServiceLocal } from '../services/auth.service';


@Component({
  selector: 'vex-feedback-report-dialog',
  templateUrl: './feedback-report-dialog.component.html',
  styleUrls: ['./feedback-report-dialog.component.scss']
})
export class FeedbackReportDialogComponent implements OnInit {


  icClose = icClose;
  feebackDetailsData: any = ''

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<FeedbackReportDialogComponent>,
    private auth: AuthServiceLocal,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.getFeedBackList()
  }

  close() {
    this.dialogRef.close()
  }



  getFeedBackList() {
    const payload = {
      "questionId": this.data.questionId,
      "teamId": this.data.teamId,
      "gender": this.data.gender,
      "teamName": this.data?.teamName
    }

    this.auth.getFeedbackDetails(payload).subscribe((response: any) => {
      if (response.status == 200) {
        console.log('hello in getFeedback details', response.data);
        this.feebackDetailsData = response.data
      } else {
        this.feebackDetailsData = ''
        this.cdr.detectChanges()
      }

    })
  }

}
