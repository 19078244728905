import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { AuthGuard } from '../@vex/services/auth.guard';


let tempData: any = localStorage.getItem('loggedUser')
tempData = JSON.parse(tempData)?.isAdmin
// console.log("temp data ", tempData)

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/new-login/new-login.module').then(m => m.NewLoginModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  },
  {
    path: 'reset-password/:id',
    loadChildren: () => import('./pages/auth/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
  },
  {
    path: 'manager.ai',
    loadChildren: () => import('./pages/auth/inivated-user-page/inivated-user.module').then(m => m.InivatedUserModule)
  },
  {
    path: '',
    component: CustomLayoutComponent, canActivate: [AuthGuard],
    children: [

      {
        path: '',
        loadChildren: () => import('./pages/dashboard-analytics/dashboard-analytics.module').then(m => m.DashboardAnalyticsModule),
      },
      {
        path: 'ai-manager',
        loadChildren: () => import('./pages/manager-ai/manager-ai.module').then(m => m.ManagerAiModule),
      },
      {
        path: 'change-password',
        loadChildren: () => import('./pages/change-password/change-password.module').then(m => m.ChangePasswordModule),
      },
      {
        path: 'profile',
        loadChildren: () => import('./pages/user-profile/user-profile.module').then(m => m.UserProfileModule),
      },
      {
        path: 'my-account',
        loadChildren: () => import('./pages/my-organisation/my-organisation.module').then(m => m.MyOrganisationModule),
      },

      {
        path: 'teams',
        loadChildren: () => import('./pages/manager-teams/manager-teams.module').then(m => m.ManagerTeamsModule),
      },


      {
        path: 'monthly-themes',
        loadChildren: () => import('./pages/monthly-themes/monthly-themes.module').then(m => m.MonthlyThemesModule),
      },
      {
        path: 'my-company',
        loadChildren: () => import('./pages/my-company/my-company.module').then(m => m.MyCompanyModule),
      },

      {
        path: 'manager-action',
        loadChildren: () => import('./pages/manager-actions/manager-actions.module').then(m => m.ManagerActionsModule),
      },

      {
        path: 'challenge',
        loadChildren: () => import('./pages/challenges/create-edit-challenge/create-edit-challenge.module')
          .then(m => m.CreateEditChallengeModule),
      },
      {
        path: 'challenge/:id',
        loadChildren: () => import('./pages/challenges/challenge-detail/challenge-detail.module')
          .then(m => m.ChallengeDetailModule),
      },
      // {
      //   path: 'launch',
      //   loadChildren: () => import('./pages/challenge-template/selected-challenges/selected-challenges.module').then(m => m.SelectedChallengesModule)
      // },
      {
        path: 'challenge/invite',
        loadChildren: () => import('./pages/challenges/invite/invite.module').then(m => m.InviteModule),
      },

      {
        path: 'coaching',
        loadChildren: () => import('./pages/coaching/coaching.module').then(m => m.CoachingModule),
      },

      {
        path: 'reward',
        loadChildren: () => import('./pages/reward/reward.module').then(m => m.RewardModule),
      },
      {
        path: 'company-challenge',
        loadChildren: () => import('./pages/automatic-challenges/automatic-challenges.module').then(m => m.AutomaticChallengesModule),
      },
      {
        path: 'challenge-quiz',
        loadChildren: () => import('./pages/challenge-quiz/challenge-quiz.module').then(m => m.ChallengeQuizModule),
      },
      {
        path: 'wellness-categories', canActivateChild: [AuthGuard],
        loadChildren: () => import('./pages/wellness-categories/wellness-categories.module').then(m => m.WellnessCategoriesModule),
        data: {
          tutorialData: {
            title: 'Wellness Categories!',
            text: 'Create your own mental health and job satisfaction tips and advice that goes directly to the employee-app. ',
            url: '/praise-colleague',
            buttonName: 'Next',
            positionTop: '520px'
          }
        }
      },
      {
        path: 'wellness/:id',
        loadChildren: () => import('./pages/wellness/wellness.module').then(m => m.WellnessModule)
      },

      // {
      //   path: 'reward-amount', canActivateChild: [AuthGuard],
      //   loadChildren: () => import('./pages/donation-goals/donation-goals.module').then(m => m.DonationGoalsModule),
      //   data: {
      //     tutorialData: {
      //       title: 'Reward Amount!',
      //       text: 'Any points employees earn by using the app will be converted into real cash. This is where you determine the amount that can be donated to reward or similar organizations your company is interested in helping.',
      //       url: '/reward-type',
      //       buttonName: 'Next',
      //       positionTop: '400px'
      //     }
      //   }
      // },
      // {
      //   path: 'reward-type', canActivateChild: [AuthGuard],
      //   loadChildren: () => import('./pages/ngo/ngo.module').then(m => m.NgoM65odule),
      //   data: {
      //     tutorialData: {
      //       title: 'Reward Type!',
      //       text: 'Add the reward or similar organizations you collaborate with. The reward you add here will automatically be visible to employees using the app.',
      //       url: '/wellness-categories',
      //       buttonName: 'Next',
      //       positionTop: '460px'
      //     }
      //   }
      // },
      {
        path: 'praise-colleague',
        loadChildren: () => import('./pages/praise-colleague/praise-colleague.module').then(m => m.PraiseColleagueModule),
        data: {
          tutorialData: {
            title: 'Praise Colleague!',
            text: 'Recognize and appreciate your colleagues by praising them here.',
            url: tempData == 1 ? '/roles' : 'slack-integration',
            buttonName: 'Next',
            positionTop: tempData == 1 ? '580px' : '400px'
          }
        }
      },
      {
        path: 'praise-histroy',
        loadChildren: () => import('./pages/praise-colleague/praise-histroy/praise-histroy.module').then(m => m.PraiseHistroyModule)
      },
      {
        path: 'roles',
        loadChildren: () => import('./pages/ai-manager-role/ai-manager-role.module').then(m => m.AiManagerRoleModule),
      },
      // {
      //   path: 'users',
      //   loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule),
      //   data: {
      //     tutorialData: {
      //       title: 'Users!',
      //       text: 'Manage different type of roles.',
      //       url: '/notifications',
      //       buttonName: 'Next'
      //     }
      //   }
      // },
      // {
      //   path: 'roles', canActivateChild: [AuthGuard],
      //   loadChildren: () => import('./pages/r').then(m => m.RolesModule),
      //   data: {
      //     tutorialData: {
      //       title: 'Roles!',
      //       text: 'Manage different type of roles.',
      //       url: '/send-notification',
      //       buttonName: 'Next',
      //       positionTop: '640px'
      //     }
      //   }
      // },
      {
        path: 'send-notification', canActivateChild: [AuthGuard],
        loadChildren: () => import('./pages/notifications/send-notification/send-notification.module').then(m => m.SendNotificationModule),
        data: {
          tutorialData: {
            title: 'Send Notifications!',
            text: 'Use this feature anytime you would like to reach out to your employees. We recommend that you use this feature if the message is correlated with employee’s wellbeing.',
            url: '/slack-integration',
            buttonName: 'Next',
            positionTop: '705px'
          }
        }
      },
      {
        path: 'notifications', canActivateChild: [AuthGuard],
        loadChildren: () => import('./pages/notifications/notifications.module').then(m => m.NotificationsModule),
        data: {
          tutorialData: {
            title: 'Notifications!',
            text: 'Use this feature anytime you would like to reach out to your employees. We recommend that you use this feature if the message is correlated with employee’s wellbeing.',
            url: '/slack-integration',
            buttonName: 'Next',
            positionTop: '700px'
          }
        }
      },
      {
        path: 'slack-integration',
        loadChildren: () => import('./pages/slack-intergration/slack-intergration.module').then(m => m.SlackIntergrationModule),
        data: {
          tutorialData: {
            title: 'Slack Integration!',
            text: 'Integrate with Slack to boost communication and engagement. ',
            url: '/',
            buttonName: 'Finish',
            positionTop: tempData == 1 ? '760px' : '460px'

          }
        }
      },

      {
        path: 'events',
        loadChildren: () => import('./pages/event/event.module').then(m => m.EventModule),
      },
      {
        path: '',
        loadChildren: () => import('./pages/challenge-template/challenge-template.module').then(m => m.ChallengeTemplateModule),
        data: {
          tutorialData: {
            title: 'Library!',
            text: 'View and or create challenges for both teams or individuals. Employees are more engaged when they participate in team challenges.',
            url: '/teams',
            buttonName: 'Next',
            positionTop: '160px'
          }
        }
      },
      {
        path: 'error-404',
        loadChildren: () => import('./pages/errors/error-404/error-404.module').then(m => m.Error404Module)
      },
      {
        path: 'error-500',
        loadChildren: () => import('./pages/errors/error-500/error-500.module').then(m => m.Error500Module)
      },
      {
        path: 'permission-denied',
        loadChildren: () => import('./pages/errors/permission-denied/permission-denied.module').then(m => m.PermissionDeniedModule)
      },
      {
        path: '**',
        loadChildren: () => import('./pages/errors/error-404/error-404.module').then(m => m.Error404Module)
      },


    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'corrected',
      anchorScrolling: 'enabled',
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

