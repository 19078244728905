import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { ApiEndPointUrl } from '../utils/systemEnums';
import { DataService } from './data.service';
import { LocalStoreService } from './local-store.service';

@Injectable({
    providedIn: 'root'
})
export class AuthServiceLocal {
    _configuration = {
        API_ENDPOINT: ApiEndPointUrl,
    };

    public loadSelectedChallengeDataSubject = new BehaviorSubject<boolean>(false);
    public loadSelectedChallengeData = this.loadSelectedChallengeDataSubject.asObservable();

    constructor(private router: Router, private _dataService: DataService, private store: LocalStoreService) {

    }

    isLoggedIn() {
        if (this.store.getItem('token')) {
            return true;
        }
        return false;
    }

    getToken() {
        return this.store.getItem('token');
    }

    getUser() {
        return this.store.getItem('loggedUser');
    }

    getSidebarData() {
        return this.store.getItem('sidebarData');
    }

    isAdminLogin() {
        const user = this.store.getItem('loggedUser');
        return user && user?.isAdmin === 1;
    }


    getUtcTime(startDateP, endDateP) {

        var startDate = new Date(startDateP);
        var month: any = startDate.getMonth();
        month = month < 10 ? '0' + month : month;
        var NewStartDate = Date.UTC(startDate.getFullYear(), month, startDate.getDate())

        var endDate = new Date(endDateP);
        var month1: any = endDate.getMonth();
        month1 = month1 < 10 ? '0' + month1 : month1;
        var NewEndDate = Date.UTC(endDate.getFullYear(), month1, endDate.getDate())

        return { 'startDate': NewStartDate, 'endDate': NewEndDate };
    }



    /**
    * get api version  
    *  
    * **/
    public getCurrentApiVersion() {
        return this._dataService.getDataWithoutHeader('https://api.empida.com/api/lib/get-version-for-org-user');
    }

    /**
     * upload image
     * */
    public adminUploadImage(body) {
        return this._dataService.postDataWithHeaderFormData(
            this._configuration.API_ENDPOINT + '/common/upload-image', body
        );
    }

    /**
     * Challenge Module
     * */
    public organisationAddChallenge(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/addChallenge', body
        );
    }
    public organisationGetChallenges(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/getAllChallenge', body
        );
    }
    public getOrganisationChallengeDetail(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/getChallengeDetail', body
        );
    }
    public orgOngoingDeleteChallenge(body) {
        return this._dataService.deleteDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/delete-ongoing-challenge', body
        );
    }
    public organisationEditChallenge(body) {
        return this._dataService.putDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/updateChallenge', body
        );
    }

    public getChallengeDetail(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/challenge/challengeDetails', body
        );
    }

    public getGroupChallenges() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/get-monthly-challenges',
        );
    }

    public getGroupChallengeDetails(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/get-monthly-challenge-detail', body
        );
    }

    public getGroupChallengeLeaderBoard(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/get-cumulative-leaderBoard', body
        );
    }




    public getAllDashboardData() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/get-dashboard-data',
        );
    }

    public getFilteredData(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/get-all-year-and-month', body
        );
    }

    // getAllDashboardData() {
    //     return new Promise((resolve, reject) => {
    //         this._dataService.getDataWithHeader(this._configuration.API_ENDPOINT + '/organisation/get-dashboard-data').subscribe((result: any) => {
    //         resolve(result)

    //       }, reject)
    //     })
    //   }




    /**
     * Default Challenge Module
     * */
    public adminAddDefaultChallenge(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/admin/createDefaultChallenge', body
        );
    }
    public adminGetDefaultChallenges() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + '/admin/getAllDefaultChallenge'
        );
    }
    public adminEditDefaultChallenge(body) {
        return this._dataService.putDataWithHeader(
            this._configuration.API_ENDPOINT + '/admin/updateDefaultChallenge', body
        );
    }
    public getRewardImages() {
        return this._dataService.getDataWithHeader(
            'http://44.231.148.137:3001/api/v1/challenge/rewardIcons'
        );
    }
    public getChallengeImages() {
        return this._dataService.getDataWithHeader(
            'http://44.231.148.137:3001/api/v1/challenge/getChallengeImages'
        );
    }


    /**
     * Ngo Module
     * */
    public organisationAddNgo(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/addNGO', body
        );
    }

    public organisationEditNgo(body) {
        return this._dataService.putDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/updateNGO', body
        );
    }
    public organisationDeleteNgo(body) {
        return this._dataService.deleteDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/deleteNGO', body
        );
    }

    public organisationGetNgo() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/getAllNgoList'
        );
    }

    /***
     *  Organisation Donation Goals Module
     * */
    public getAllDonationGoals(year) {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/getALLDonationGoal/' + year
        );
    }
    public addDonationGoal(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/logDonationGoals', body
        );
    }
    public updateDonationGoal(body) {
        return this._dataService.putDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/updateDonationGoal', body
        );
    }
    public deleteDonationGoal(body) {
        return this._dataService.deleteDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/deleteDonationGoal', body
        );
    }


    /***
     *  Organisation Teams Module
     * */
    public getOrganisationTeams(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/getAllTeamByOU', body
        );
    }



    public addOrganisationTeam(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/createTeam', body
        );
    }
    public updateOrganisationTeam(body) {
        return this._dataService.putDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/updateTeam', body
        );
    }
    public deleteOrganisationTeam(body) {
        return this._dataService.deleteDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/deleteTeam', body
        );
    }

    /**
     * 
     * organization department module
     * 
     */
    public addNewDepartment(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/add-department', body
        );
    }
    public updateDepartment(body) {
        return this._dataService.putDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/edit-department', body
        );
    }

    public getAllDepartmentList() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/get-all-department'
        );
    }

    public deleteDepartment(body) {
        return this._dataService.deleteDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/delete-department', body
        );
    }






    /***
     *  Organisation Teams Members Module
     * */
    public getAllOrganisationEmployees() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/GetAllEmployees'
        );
    }

    public disableEmployee(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/deleteUserByOrganizationId', body
        );
    }

    public getAllTeamMembers(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/getAllTeamMember', body
        );
    }
    public addEmployeeInTeam(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/addTeamMembers', body
        );
    }


    /***
     *  Organisation User Module
     * */
    public getOrganisationUsers() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/getAllOrganisationUser'
        );
    }
    public addOrganisationUser(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/addOrganisationUser', body
        );
    }
    public updateOrganisationUser(body) {
        return this._dataService.putDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/updateOrganisationUser', body
        );
    }
    public deleteOrganisationUser(body) {
        return this._dataService.deleteDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/deleteOrganisationUser', body
        );
    }


    /***
     *  Organisation User Module
     * */
    public getOrganisationRoles() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/getAllOrganizationUserRoles'
        );
    }
    public addOrganisationRole(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/AssignRoleToEmployee', body
        );
    }
    public updateOrganisationRole(body) {
        return this._dataService.putDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/UpdateRoleOfEmployee', body
        );
    }
    public deleteOrganisationRole(body) {
        return this._dataService.deleteDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/DeleteOrganisationUserRoles', body
        );
    }


    /****************************************************/
    /**************** Organisation APis *****************/
    /****************************************************/
    public ChangePassword(body) {
        return this._dataService.putDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/changePassword', body
        );
    }
    public organisationLogin(body) {
        return this._dataService.postDataWithoutHeader(
            this._configuration.API_ENDPOINT + '/organisation/logIn', body
        );
    }
    public resetPassword(body) {
        return this._dataService.postDataWithoutHeader(
            this._configuration.API_ENDPOINT + '/organisation/resetPassword', body
        );
    }
    public forgotPassword(body) {
        return this._dataService.postDataWithoutHeader(
            this._configuration.API_ENDPOINT + '/organisation/forgotPassword', body
        );
    }

    /**
     * Get Dashboard Data
     * */
    public getOrganisationDashboard(year) {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/getUserDashboard/' + year
        );
    }
    public getDashboardOnGoingChallenge() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/getLatestCompanyOrTeamChallenge'
        );
    }

    public getWalkAndTalkData(year) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/WalkTalkMeetingChartData', year
        );
    }

    public getCo2ChartData(year) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/GetCO2Chart', year
        );
    }

    public getDonationChartData(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/GetMonthlyDonation', body
        );
    }

    public getCompanyChallengeLeaderboard(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/getCompanyChallengeLeaderboard', body
        );
    }
    public getOrganisationDetail() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/getOrganisationDetails'
        );
    }
    public updateOrganisationDetail(body) {
        return this._dataService.putDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/updateOrganisationDetail', body
        );
    }
    public getUserProfile() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/getProfile'
        );
    }
    public editUserProfile(body) {
        return this._dataService.putDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/editProfile', body
        );
    }
    public getAllUsers() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/getAllUsers'
        );
    }
    public getInvitedUsersByChallengeId(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/getInvitedUsersByChallengeId', body
        );
    }
    public sendChallengeInvitation(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/challenge-invite', body
        );
    }
    public getAllNotification() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/getAllOrganisationNotification'
        );
    }
    public sendNotification(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/sendNotification', body
        );
    }
    public updateTutorial() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/updateTutorialCheck'
        );
    }
    public sendEmployeeInvitation(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/sendOrganisationInvitation', body
        );
    }

    public getIntergrationStatus() {
        return this._dataService.getDataWithHeaderManager(
            this._configuration.API_ENDPOINT + '/slack/get-integration-status'
        );
    }
    public getSlackOrgSetting() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + '/slack/get-slack-org-setting '
        );
    }

    public updateSlackEventStatus(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/slack/update-slack-org-setting', body
        );
    }

    public disableSlackIntergration() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + '/slack/stop-uninstall-slack'
        );
    }


    /**
     * Automatic Challenge Module
     * */
    public adminAddAutomaticChallenge(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/CreateOrganisationAutomaticChallenge', body
        );
    }

    public getEmployeeGraphData(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/challenge-myself-graph-details', body
        );
    }

    public CheckToStopAutomaticChallenge() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/CheckToStopAutomaticChallenge',
        );
    }
    public adminGetAutomaticChallenges() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/getAllAutomaticDefaultChallenge'
        );
    }
    public getAutomaticChallengeById(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/getAutomaticDefaultChallengeById', body
        );
    }
    public adminEditAutomaticChallenge(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/UpdateOrganisationAutomaticChallenge', body
        );
    }
    public stopAutoMaticChallenge(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/StopCurrentAutomaticChallenge', body
        );
    }

    /***
     *  Challenge Quiz Module
     * */
    public getAllChallengeQuiz(body) {

        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/GetAllChallengeQuizByOrg', body
        );
    }
    public adminGetChallengeQuizDetail(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/getChallengeQuizDetail', body
        );
    }
    public adminAddChallengeQuiz(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/addChallengeQuiz', body
        );
    }
    public adminUpdateChallengeQuiz(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/updateChallengeQuiz', body
        );
    }
    public adminDeleteChallengeQuiz(body) {
        return this._dataService.deleteDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/deleteChallengeQuiz', body
        );
    }

    public GetLastCompanyChallengeByCategoryId(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/GetLastCompanyChallengeByCategoryId', body
        );
    }

    /** COMBINATION CHALENGE ***/
    public UpdateQuestionOfCombinationChallenge(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/UpdateQuestionOfCombinationChallenge', body
        );
    }

    public StoreCombinationChallengeContentNew(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/StoreCombinationChallengeContentNew', body
        );
    }

    /** COMBINATION CHALENGE ***/
    public getAllChallengeTemplates(id, isDirect?, challengeExist?) {
        if (isDirect == undefined) {
            return this._dataService.getDataWithHeader(
                this._configuration.API_ENDPOINT + '/organisation/getChallengeTemplate/' + id
            );
        } else if (challengeExist == undefined) {
            return this._dataService.getDataWithHeader(
                this._configuration.API_ENDPOINT + '/organisation/getChallengeTemplate/' + id + '/' + isDirect
            );
        }
        else {
            return this._dataService.getDataWithHeader(
                this._configuration.API_ENDPOINT + '/organisation/getChallengeTemplate/' + id + '/' + isDirect + '/' + challengeExist
            );
        }



    }
    public getAllManagerChallenge() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/getManagerChallengeTemplate'
        );
    }

    public getIsAnyCompanyChallengeExists() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/IsAnyCompanyChallengeExists'
        );
    }


    public getOngoingChallenge(id) {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/getOngoingChallengeQueue/' + id
        );
    }

    public organisationDeleteChallenge(body) {
        return this._dataService.deleteDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/delete-ongoing-challenge ', body
        );
    }


    public addManagerChallenges(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/addManagerChallengeTemplate', body
        );
    }

    public addMyselfChallengeForUsers(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/launch-myself-ch-for-users', body
        );
    }

    public launchManagerChallenges(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/launchManagerTemplates', body
        );
    }
    public deleteManagerChallenge(body) {
        return this._dataService.deleteDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/deleteManagerChallengeTemplate', body
        );
    }

    /***
     * praise colleague Module
     * */

    public getAllMemberOrTeam(body?) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/appreciation/getall-memberorteam', body
        );
    }

    public appreciationSendEmoji(body?) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/appreciation/send-emoji', body
        );
    }

    public getDonationYears() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/YearFilter',
        );
    }


    /***
         * wellness categories
         * */

    public getAllWellnessCategories(body?) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/GetOrganisationWellnessCategories', body
        );
    }

    public addWellnessCategories(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/addWellnessCategory', body
        );
    }

    public deleteWellnessCategory(body) {
        return this._dataService.deleteDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/deleteWellnessCategory', body
        );
    }

    public UploadImage(body) {
        return this._dataService.postDataWithHeaderFormData(
            this._configuration.API_ENDPOINT + '/organisation/upload-image', body
        );
    }

    public EditWellnessCategory(body) {
        return this._dataService.putDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/updateWellnessCategory', body
        );
    }

    public ReuseWellnessCategory(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/ReuseWellnessCategory', body
        );
    }

    public WellnessHeadCategory() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/getWellnessHeadCategory',
        );
    }


    public AddWellness(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/addWellness', body
        );
    }

    public EditWellness(body) {
        return this._dataService.putDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/updateWellness', body
        );
    }
    public ReuseWellness(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/ReuseWellness', body
        );
    }
    public DeleteWellness(body) {
        return this._dataService.deleteDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/deleteWellness', body
        );
    }
    public GetWellnessDetailById(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/getWellnessById', body
        );
    }
    public GetWellness(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/getAllWellnessByCategoryId', body
        );
    }

    public getPraiseHistroy(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/get-praise-history', body
        );
    }

    public editOnGoingChallenge(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/getCompanyChallengeById', body
        );
    }
    public updateOnGoingChallenge(body) {
        return this._dataService.putDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/updateCompanyChallenge', body
        );
    }

    public addHeadDepartment(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/add-head-category', body
        );
    }


    /***
         * event module 
         * */
    public getEventList() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/get-all-event',
        );
    }

    public deleteEvent(body) {
        return this._dataService.deleteDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/delete-event', body
        );
    }

    public addEvent(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/add-event', body
        );
    }

    public getEventData(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/get-event-detail', body
        );
    }
    public updateEvent(body) {
        return this._dataService.putDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/update-event', body
        );
    }


    // program module 

    public getSeniorLeaderData(body) {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/get-all-colleagues/' + body
        );
    }


    public addProgramData(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/add-program', body
        );
    }

    public getAllProgramData() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/get-all-program',
        );
    }

    public deleteProgamData(body) {
        return this._dataService.deleteDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/delete-program', body
        );
    }

    public getProgramDetails(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/get-program-detail', body
        );
    }

    public updateProgramData(body) {
        return this._dataService.putDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/update-program', body
        );
    }


    // get themes
    public getAllThemes() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/get-all-theme'
        )
    }

    // get theme details
    public getThemeDetail(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/get-theme-detail', body
        )
    }

    // how theme work
    public getHowItWork() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/get-theme-how-its-work'
        )
    }

    // launch-theme-challenges
    public onLanuchTheme(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/launch-theme-challenges', body
        )
    }

    public getAllLaunchedTheme() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/get-all-launched-theme'
        )
    }

    // get theme details
    public getLaunchedThemeDetails(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/get-launched-theme-info', body
        )
    }



    public deleteOrgLaunchTheme(body) {
        return this._dataService.deleteDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/delete-orgTheme ', body
        )
    }

    public updateLanuchedTheme(body) {
        return this._dataService.putDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/update-orgTheme', body
        )
    }


    public getLeaderBoardData(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/get-leader-board-data', body
        )
    }


    public getTeamDetail(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/get-team-detail', body
        )
    }

    public setFeedStatus(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/change-org-feed-setting', body
        )
    }



    public ManagerAi(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/manager-ai', body
        )
    }

    public getAllManagerAiSurvey() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/get-all-survey-reports'
        )
    }


    public deleteManagerAiSurvey(body) {
        return this._dataService.deleteDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/delete-survey-report', body
        )
    }



    public getKpiAreaListData() {
        return this._dataService.getDataWithHeaderManager(
            this._configuration.API_ENDPOINT + '/organisation/get-kpi-area-list',
        )
    }


    public getSelectedKpiList(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/get-challenge-template-by-kpi', body
        )
    }

    public getAllTeamList() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/get-all-teams',
        )
    }

    public updatekpisStatus(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/add-update-kpi-ids', body
        )
    }

    public updateOnboardStatus() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/update-onboard-status',
        )
    }


    public getStartDateForTeamSelection(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/get-challenge-startdate-by-teamids', body
        )
    }


    //new manager on boarding flow 

    public ManagerEmailVerify(body) {
        return this._dataService.postDataWithoutHeader(
            this._configuration.API_ENDPOINT + '/organisation/send-verification-code-on-email', body
        )
    }


    public verifyManagerOtp(body) {
        return this._dataService.postDataWithoutHeader(
            this._configuration.API_ENDPOINT + '/organisation/verify-email', body
        )
    }

    public getRecommendStartDate() {
        return this._dataService.getDataWithHeaderManager(
            this._configuration.API_ENDPOINT + '/organisation/get-recommended-date-for-demo'
        )
    }


    public getTemplateForLaunch() {
        return this._dataService.getDataWithHeaderManager(
            this._configuration.API_ENDPOINT + '/organisation/get-added-template'
        )
    }



    public managerDetails(body) {
        return this._dataService.postDataWithHeaderManager(
            this._configuration.API_ENDPOINT + '/organisation/tell-us-about-yourself', body
        )
    }


    public getKpiAreaListManager() {
        return this._dataService.getDataWithHeaderManager(
            this._configuration.API_ENDPOINT + '/organisation/get-kpi-area-list',
        )
    }

    public getInCompleteManagerInfo(body) {
        return this._dataService.postDataWithoutHeader(
            this._configuration.API_ENDPOINT + '/organisation/get-demo-manager-info', body
        )
    }

    public saveTeamName(body) {
        return this._dataService.postDataWithHeaderManager(
            this._configuration.API_ENDPOINT + '/organisation/create-team-with-department', body
        )
    }

    public addTeamSize(body) {
        return this._dataService.postDataWithHeaderManager(
            this._configuration.API_ENDPOINT + '/organisation/add-team-size', body
        )
    }

    public saveMangerFocusArea(body) {
        return this._dataService.postDataWithHeaderManager(
            this._configuration.API_ENDPOINT + '/organisation/add-update-kpi-ids', body
        )
    }


    public getChallengeTemplate(body) {
        return this._dataService.postDataWithHeaderManager(
            this._configuration.API_ENDPOINT + '/organisation/get-template-by-kpi-id', body
        )
    }


    public launchTemplateChallenge(body) {
        return this._dataService.postDataWithHeaderManager(
            this._configuration.API_ENDPOINT + '/organisation/launch-demo-challenge', body
        )
    }

    public launchWeeklyAction(body) {
        return this._dataService.postDataWithHeaderManager(
            this._configuration.API_ENDPOINT + '/organisation/store-template-notification-data', body
        )
    }

    public launchManagerTemplateChallenge(body) {
        return this._dataService.postDataWithHeaderManager(
            this._configuration.API_ENDPOINT + '/organisation/launch-other-demo-challenge', body
        )
    }
    public launchSurveyTemplateChallenge(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/launch-challenge-from-survey-ai', body
        )
    }


    public addManagerChallengeTemplate(body) {
        return this._dataService.postDataWithHeaderManager(
            this._configuration.API_ENDPOINT + '/organisation/add-demo-challenge-template', body
        )
    }

    public getServiceList() {
        return this._dataService.getDataWithHeaderManager(
            this._configuration.API_ENDPOINT + '/organisation/get-service-list',
        )
    }

    public updateStepCountData(body) {
        return this._dataService.postDataWithHeaderManager(
            this._configuration.API_ENDPOINT + '/organisation/update-step-count', body
        )
    }

    public storeServiceRole(body) {
        return this._dataService.postDataWithHeaderManager(
            this._configuration.API_ENDPOINT + '/organisation/store-service-for-orguser', body
        )
    }

    public getKpiAreaList() {
        return this._dataService.getDataWithHeaderManager(
            this._configuration.API_ENDPOINT + '/organisation/get-kpi-area-list'
        )
    }

    public saveManagerHeadCategory(body) {
        return this._dataService.postDataWithHeaderManager(
            this._configuration.API_ENDPOINT + '/organisation/store-kpi-head-category', body
        )
    }





    public sendInvitationToUsers(body) {
        return this._dataService.postDataWithHeaderManager(
            this._configuration.API_ENDPOINT + '/organisation/send-invitation-to-users', body
        )
    }

    public getManagerDashboardData(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/get-dashboard-for-demo-user', body
        )
    }


    public getManagerFocusHeadCategory(body) {
        return this._dataService.postDataWithHeaderManager(
            this._configuration.API_ENDPOINT + '/organisation/get-kpi-category-by-head-category', body
        )
    }

    public getFocusAreaList(body) {

        if (body?.isExistingTeam) {
            return this._dataService.getDataWithHeaderManager(
                this._configuration.API_ENDPOINT + '/organisation/get-kpi-by-role/' + body.roleType + '/' + body?.isExistingTeam,
            )
        } else {
            return this._dataService.getDataWithHeaderManager(
                this._configuration.API_ENDPOINT + '/organisation/get-kpi-by-role/' + body,
            )
        }
    }

    getActionFeedbackData(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/get-conclusion-grpah-data', body
        )
    }

    public getSlackChannelMembersList(teamId?) {

        if (teamId) {
            return this._dataService.getDataWithHeaderManager(
                this._configuration.API_ENDPOINT + '/organisation/get-channel-member-list/' + teamId
            )
        }
        else {
            return this._dataService.getDataWithHeaderManager(
                this._configuration.API_ENDPOINT + '/organisation/get-channel-member-list'
            )
        }
    }



    public getManagerCreatedTeams(departmentId?) {

        if (departmentId) {
            return this._dataService.getDataWithHeader(
                this._configuration.API_ENDPOINT + '/organisation/manager-created-teams/' + departmentId
            )
        }
        else {
            return this._dataService.getDataWithHeaderManager(
                this._configuration.API_ENDPOINT + '/organisation/manager-created-teams'
            )
        }

    }

    public getDepartmentList() {

        return this._dataService.getDataWithHeaderManager(
            this._configuration.API_ENDPOINT + '/organisation/get-department-list'
        )


    }




    public getManagerConclusionComment(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/get-demo-challenge-conclusion-comment', body
        )
    }




    public getChallengeComment(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/get-demo-challenge-comment', body
        )
    }


    public getSurveyAiDashboardData() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/org-panel-dashboard'
        )
    }



    public getEmployeePerformance(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/get-action-tabke-by-user', body
        )
    }

    public getUserAllComments(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/get-user-all-comments', body
        )
    }



    public setDashboardType(body) {
        return this._dataService.postDataWithHeaderManager(
            this._configuration.API_ENDPOINT + '/organisation/store-dashboard-type', body
        )
    }


    public getManagerCompletedChallenge() {
        return this._dataService.getDataWithHeaderManager(
            this._configuration.API_ENDPOINT + '/organisation/get-demo-manager-completed-challenges'
        )
    }


    public getManagerOngoingChallenge() {
        return this._dataService.getDataWithHeaderManager(
            this._configuration.API_ENDPOINT + '/organisation/get-demo-manager-ongoing-upcoming-challenges'
        )
    }


    public getIndustryTypes() {
        return this._dataService.getDataWithHeaderManager(
            this._configuration.API_ENDPOINT + '/organisation/get-industry'
        )
    }


    public getQuestionListByIndustry(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/get-queslist-by-industry', body
        )
    }


    public createSurveys(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/create-survey', body
        )
    }

    public getOngoingSurveyList(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/get-ongoing-survey-list', body
        )
    }

    public getOngoingSurveyDetail(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/get-survey-detail', body
        )
    }

    public getCompleteSurveyListData() {
        return this._dataService.getDataWithHeaderManager(
            this._configuration.API_ENDPOINT + '/organisation/completed-survey-list'
        )
    }

    public getUpcommingSurveyListData() {
        return this._dataService.getDataWithHeaderManager(
            this._configuration.API_ENDPOINT + '/organisation/get-upcoming-survey-list'
        )
    }


    public getSureveyReportProgess(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/get-process-status', body
        )
    }

    public getSurveyReportTeamList(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/get-survey-team-list', body
        )
    }


    public getSurveyFilterData(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/get-ongoing-survey', body
        )
    }


    public comapareTeamSurvey(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/compare-teams', body
        )
    }


    public genrateCsv(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/get-survey-detail-csv', body
        )
    }


    public managerBasicDetails(body) {
        return this._dataService.postDataWithHeaderManager(
            this._configuration.API_ENDPOINT + '/organisation/set-organisation-details', body
        )
    }


    public getFeedbackDetails(body) {
        return this._dataService.postDataWithHeaderManager(
            this._configuration.API_ENDPOINT + '/organisation/get-open-ended-feedback-details', body
        )
    }


    public getSurveyDuration() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/get-duration-list',
        )
    }


    public getAiMangerRoles() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/get-list-of-added-managers',
        )
    }

    public addAiManagerRole(payload) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + '/organisation/add-detail-of-user', payload
        )
    }







}